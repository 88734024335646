@charset "UTF-8";
:root {
  --version: 1.39;
}

* {
  margin: 0;
  border: 0;
  padding: 0;
}

.mobile-visible,
.tablet-visible,
.desktop-visible {
  display: none;
}

@media (max-width: 575.98px) {
  .mobile-visible {
    display: block !important;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .tablet-visible {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .desktop-visible {
    display: block !important;
  }
}
a:not([class*=btn]):not([class*=card-link]) {
  text-decoration: none;
}

a:not([class*=btn]):not([class*=card-link]):hover {
  text-decoration: underline;
}

.dropdown-item {
  text-decoration: none !important;
}

.btn.btn-icon {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-align: center;
          align-items: center;
}

button:focus {
  outline: none;
}

.flex-none,
svg {
  -webkit-box-flex: 0 !important;
          flex: none !important;
}

a svg,
.btn svg {
  pointer-events: none;
}

ul,
li {
  list-style-type: none;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.hidden {
  display: none !important;
}

.overflow-scroll-vertical-only {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.dropdown.dropdown-sm .dropdown-toggle::after, .dropdown.dropdown-sm .dropdown-toggle::before,
.dropleft.dropdown-sm .dropdown-toggle::after,
.dropleft.dropdown-sm .dropdown-toggle::before,
.dropup.dropdown-sm .dropdown-toggle::after,
.dropup.dropdown-sm .dropdown-toggle::before,
.dropright.dropdown-sm .dropdown-toggle::after,
.dropright.dropdown-sm .dropdown-toggle::before {
  width: 0.75rem;
  height: 0.75rem;
}
.dropdown.dropdown-lg .dropdown-toggle::after, .dropdown.dropdown-lg .dropdown-toggle::before,
.dropleft.dropdown-lg .dropdown-toggle::after,
.dropleft.dropdown-lg .dropdown-toggle::before,
.dropup.dropdown-lg .dropdown-toggle::after,
.dropup.dropdown-lg .dropdown-toggle::before,
.dropright.dropdown-lg .dropdown-toggle::after,
.dropright.dropdown-lg .dropdown-toggle::before {
  width: 1.125rem;
  height: 1.125rem;
}
.dropdown::after, .dropdown::before,
.dropleft::after,
.dropleft::before,
.dropup::after,
.dropup::before,
.dropright::after,
.dropright::before {
  width: 1rem;
  height: 1rem;
}

.dropdown-toggle::after, .dropdown-toggle::before {
  width: 1rem;
  height: 1rem;
  background-color: currentColor;
  display: inline-block;
  vertical-align: sub;
  content: "";
  -webkit-mask-size: contain;
          mask-size: contain;
}
.dropdown-toggle::after {
  margin-left: 0.255em;
  -webkit-mask-image: url("data:image/svg+xml,<svg width=%271em%27 height=%271em%27 viewBox=%270 0 16 16%27 fill=%27currentColor%27 xmlns=%27http://www.w3.org/2000/svg%27><path fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z%27></path></svg>");
          mask-image: url("data:image/svg+xml,<svg width=%271em%27 height=%271em%27 viewBox=%270 0 16 16%27 fill=%27currentColor%27 xmlns=%27http://www.w3.org/2000/svg%27><path fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z%27></path></svg>");
}
.dropdown-toggle::before {
  display: none !important;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-toggle::after, .dropdown-toggle::before {
  -webkit-box-flex: 0;
          flex: none;
}

.dropup .dropdown-toggle::after, .dropup .dropdown-toggle::before {
  width: 1rem;
  height: 1rem;
  background-color: currentColor;
  display: inline-block;
  vertical-align: sub;
  content: "";
  -webkit-mask-size: contain;
          mask-size: contain;
}
.dropup .dropdown-toggle::after {
  margin-left: 0.255em;
  -webkit-mask-image: url("data:image/svg+xml,<svg width=%271em%27 height=%271em%27 viewBox=%270 0 16 16%27 fill=%27currentColor%27 xmlns=%27http://www.w3.org/2000/svg%27><path fill-rule=%27evenodd%27 d=%27M7.646 4.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 5.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z%27></path></svg>");
          mask-image: url("data:image/svg+xml,<svg width=%271em%27 height=%271em%27 viewBox=%270 0 16 16%27 fill=%27currentColor%27 xmlns=%27http://www.w3.org/2000/svg%27><path fill-rule=%27evenodd%27 d=%27M7.646 4.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 5.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z%27></path></svg>");
}
.dropup .dropdown-toggle::before {
  display: none !important;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::after, .dropleft .dropdown-toggle::before {
  width: 1rem;
  height: 1rem;
  background-color: currentColor;
  display: inline-block;
  vertical-align: sub;
  content: "";
  -webkit-mask-size: contain;
          mask-size: contain;
}
.dropleft .dropdown-toggle::after {
  margin-left: 0.255em;
}
.dropleft .dropdown-toggle::before {
  display: none !important;
}
.dropleft .dropdown-toggle::after {
  display: none !important;
}
.dropleft .dropdown-toggle::before {
  margin-right: 0.255em;
  -webkit-mask-image: url("data:image/svg+xml,<svg width=%271em%27 height=%271em%27 viewBox=%270 0 16 16%27 fill=%27currentColor%27 xmlns=%27http://www.w3.org/2000/svg%27><path fill-rule=%27evenodd%27 d=%27M11.354 1.646a.5.5 0 010 .708L5.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z%27></path></svg>");
          mask-image: url("data:image/svg+xml,<svg width=%271em%27 height=%271em%27 viewBox=%270 0 16 16%27 fill=%27currentColor%27 xmlns=%27http://www.w3.org/2000/svg%27><path fill-rule=%27evenodd%27 d=%27M11.354 1.646a.5.5 0 010 .708L5.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z%27></path></svg>");
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after, .dropright .dropdown-toggle::before {
  width: 1rem;
  height: 1rem;
  background-color: currentColor;
  display: inline-block;
  vertical-align: sub;
  content: "";
  -webkit-mask-size: contain;
          mask-size: contain;
}
.dropright .dropdown-toggle::after {
  margin-left: 0.255em;
  -webkit-mask-image: url("data:image/svg+xml,<svg width=%271em%27 height=%271em%27 viewBox=%270 0 16 16%27 fill=%27currentColor%27 xmlns=%27http://www.w3.org/2000/svg%27><path fill-rule=%27evenodd%27 d=%27M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z%27></path></svg>");
          mask-image: url("data:image/svg+xml,<svg width=%271em%27 height=%271em%27 viewBox=%270 0 16 16%27 fill=%27currentColor%27 xmlns=%27http://www.w3.org/2000/svg%27><path fill-rule=%27evenodd%27 d=%27M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z%27></path></svg>");
}
.dropright .dropdown-toggle::before {
  display: none !important;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.page-item.active .page-link {
  border-bottom: 1px solid currentColor;
}

.tns-nav {
  margin-top: 0 !important;
}

.goal_section_html .markdown ul li {
  list-style-type: none !important;
}
.goal_section_html .markdown ul li::before {
  content: "•";
  color: #ff3944;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1rem;
}

.landing-header {
  background: var(--background_image);
  background-position: center;
  background-size: cover;
  height: 200px;
}
@media (min-width: 576px) {
  .landing-header {
    margin-bottom: -130px;
  }
}
@media (max-width: 575.98px) {
  .landing-header {
    margin-bottom: -190px;
  }
}

.tns-nav {
  text-align: center;
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}
.tns-nav > [aria-controls] {
  width: 0.5625rem;
  height: 0.5625rem;
  padding: 0;
  margin: 0 5px;
  border-radius: 50%;
  background: #3e6a91;
  opacity: 0.5;
  border: 0;
}
.tns-nav > [aria-controls].tns-nav-active {
  background: #ff3944;
  opacity: 1;
}

ins {
  min-width: 300px;
  min-height: 50px;
}

.env-preprod ins[data-ad-slot],
.env-dev ins[data-ad-slot] {
  background: #dcecf5 !important;
  position: relative;
}
.env-preprod ins[data-ad-slot]::before,
.env-dev ins[data-ad-slot]::before {
  content: "Pub";
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
}

.markdown,
.ck.ck-content {
  white-space: pre-line !important;
}
.markdown ol,
.markdown ul,
.ck.ck-content ol,
.ck.ck-content ul {
  display: block;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  -webkit-padding-start: 40px;
          padding-inline-start: 40px;
  margin-top: 1em;
}
.markdown ul, .markdown ul li,
.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: disc !important;
}
.markdown ol, .markdown ol li,
.ck.ck-content ol,
.ck.ck-content ol li {
  list-style-type: decimal !important;
}
.markdown a,
.ck.ck-content a {
  text-decoration: underline;
}
.markdown hr,
.ck.ck-content hr {
  clear: both;
  margin: 15px 0;
  height: 0;
  overflow: hidden;
  border: none;
  background: transparent;
  border-bottom: 2px solid #ddd;
  padding: 0;
}
.markdown br:last-child:not(:only-child),
.ck.ck-content br:last-child:not(:only-child) {
  display: none;
}
.markdown h1:not(:first-child),
.markdown h2:not(:first-child),
.markdown h3:not(:first-child),
.markdown h4:not(:first-child),
.markdown h5:not(:first-child),
.markdown h6:not(:first-child),
.ck.ck-content h1:not(:first-child),
.ck.ck-content h2:not(:first-child),
.ck.ck-content h3:not(:first-child),
.ck.ck-content h4:not(:first-child),
.ck.ck-content h5:not(:first-child),
.ck.ck-content h6:not(:first-child) {
  margin-top: 1rem;
}
.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6,
.ck.ck-content h1,
.ck.ck-content h2,
.ck.ck-content h3,
.ck.ck-content h4,
.ck.ck-content h5,
.ck.ck-content h6 {
  margin-bottom: 0.75rem;
}

.sticky-top + .navbar.fixed-bottom:before {
  box-shadow: 2px 6px 30px 0 #042342, inset 0 0 50px 0 rgba(4, 35, 66, 0.2196078431);
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

@media (max-width: 575.98px) {
  .dropdown-menu {
    min-width: 100%;
  }
}
.dropdown-toggle {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

.input-group .btn {
  align-self: stretch;
}

.fullscreen-tunnel-wrapper {
  overflow: hidden;
  height: 100vh;
}
@media (min-width: 576px) {
  .fullscreen-tunnel-wrapper {
    height: -webkit-calc(100vh - 110px);
    height: calc(100vh - 110px);
  }
}
@media (max-width: 575.98px) {
  .fullscreen-tunnel-wrapper {
    height: -webkit-calc(100vh - 63px);
    height: calc(100vh - 63px);
  }
}
.fullscreen-tunnel-wrapper .fullscreen-tunnel form {
  height: 100%;
}
.fullscreen-tunnel-wrapper .fullscreen-tunnel .tunnel-timeline {
  position: relative;
  overflow-y: hidden;
}
.fullscreen-tunnel-wrapper .fullscreen-tunnel .tunnel-timeline > .tunnel-timeline-inner {
  overflow: auto;
  height: 100%;
}
.fullscreen-tunnel-wrapper .fullscreen-tunnel .tunnel-timeline:before {
  content: "";
  position: absolute;
  z-index: 890;
  left: 50%;
  width: 1px;
  background: #a7afc3;
}
.fullscreen-tunnel-wrapper .fullscreen-tunnel .tunnel-timeline.tunnel-timeline-down.tunnel-timeline-up:before {
  top: 0;
  height: 100vh;
}
.fullscreen-tunnel-wrapper .fullscreen-tunnel .tunnel-timeline.tunnel-timeline-up:not(.tunnel-timeline-down):before {
  top: 0;
  height: 50vh;
}
.fullscreen-tunnel-wrapper .fullscreen-tunnel .tunnel-timeline.tunnel-timeline-down:not(.tunnel-timeline-up):before {
  top: 50%;
  height: 50vh;
}
.fullscreen-tunnel-wrapper .fullscreen-tunnel .tunnel-card {
  position: relative;
  z-index: 900;
}
@media (max-width: 575.98px) {
  .fullscreen-tunnel-wrapper .fullscreen-tunnel .tunnel-card {
    height: 100% !important;
  }
  .fullscreen-tunnel-wrapper .fullscreen-tunnel .tunnel-card > .card-body {
    overflow-y: auto;
  }
}

input[data-toggle=wish] {
  display: none;
}
input[data-toggle=wish] + label {
  cursor: pointer;
}

.heart-fill-empty {
  z-index: -1;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.ekko-lightbox-container .ekko-lightbox-item.fade:not(.show),
.ekko-lightbox-container .ekko-lightbox-item:empty {
  display: none;
}

.chart-history {
  overflow: hidden;
  position: relative;
}

.chart-history div:first-child:after {
  content: "";
  width: 2px;
  background: #063462;
  height: 150px;
  position: absolute;
  top: 20px;
  left: 9px;
  z-index: 0;
}

.chart-history div:first-child:before {
  content: "";
  width: 2px;
  background: #063462;
  height: 150px;
  position: absolute;
  bottom: 20px;
  left: 9px;
  z-index: 0;
}

.chart-history div:first-child {
  z-index: 5;
  position: relative;
}

[data-monthly-price] {
  font-size: 1.75rem;
  line-height: 2.1rem;
}

a[data-link] {
  cursor: pointer;
}
a[data-link].btn-link:hover {
  text-decoration: underline;
}

.shiny {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 0.25rem 0.25rem 0 0;
  border-bottom: 3px solid #ff3944;
  background: #ffffff;
  padding: 0.75rem 1rem 1.25rem;
}

.reward.reward-small {
  width: 65px;
  height: 65px;
}
.reward.reward-large {
  width: 88px;
  height: 84px;
}

.reward {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  border-radius: 100%;
  position: relative;
  margin: 0 auto 2px;
}
.reward .reward-icon-small {
  width: 10px;
}
.reward .reward-icon-large {
  width: 55px;
}
.reward .reward-notification {
  width: 0.25rem;
  height: 0.25rem;
  position: absolute;
  background: #ffffff;
  display: -webkit-box;
  display: flex;
  color: #a7afc3;
  top: 0;
  right: 0;
  font-size: 11px;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  border-radius: 100%;
  border: 2px splid #e9f4fa;
}
.reward .reward-notification.reward-notification-large {
  width: 0.375rem;
  height: 0.375rem;
}

.notification-bullet {
  width: 0.25rem;
  height: 0.25rem;
  font-size: 11px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  color: #ffffff;
  background: #ff3944;
  border-radius: 100%;
}

.container.jumbo .jumbo-title {
  margin-bottom: 0.75rem;
  margin-right: 3.75rem;
  margin-left: 3.75rem;
}
.container.jumbo .jumbo-subtitle {
  line-height: 28px;
}

.offer-list-description ul {
  text-align: left !important;
  padding-left: 20px;
}
.offer-list-description ul + p {
  margin-top: 8px;
}

.offer-list-description ul li {
  list-style-type: disc !important;
  padding-top: 4px;
}

.clear {
  clear: both;
}

.buttons-pack {
  display: grid;
  -webkit-box-pack: stretch;
          justify-content: stretch;
  gap: 0.5rem !important;
  width: 100%;
}
.buttons-pack [value=prev] {
  -webkit-box-ordinal-group: 2;
          order: 1;
}
.buttons-pack [value=next] {
  -webkit-box-ordinal-group: 1;
          order: 0;
}
@media (min-width: 768px) {
  .buttons-pack {
    display: -webkit-box !important;
    display: flex !important;
    -webkit-box-pack: justify !important;
            justify-content: space-between !important;
  }
  .buttons-pack [value=prev] {
    -webkit-box-ordinal-group: 1;
            order: 0;
  }
  .buttons-pack [value=next] {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .buttons-pack *:first-child + hr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-ordinal-group: 0;
            order: -1;
    display: block !important;
  }
}
.buttons-pack hr {
  display: none;
  opacity: 0;
}

.buttons-pack.horizontal {
  display: -webkit-box !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
          justify-content: space-between !important;
}
.buttons-pack.horizontal [value=prev] {
  -webkit-box-ordinal-group: 1 !important;
          order: 0 !important;
}
.buttons-pack.horizontal [value=next] {
  -webkit-box-ordinal-group: 2 !important;
          order: 1 !important;
}
.buttons-pack.horizontal *:first-child + hr {
  -webkit-box-flex: 1 !important;
          flex-grow: 1 !important;
  -webkit-box-ordinal-group: 0 !important;
          order: -1 !important;
  display: block !important;
}

svg.primary {
  vertical-align: -0.21rem;
}

svg.system {
  vertical-align: -0.44rem;
}

.bsiw {
  width: var(--ui-bsiw) !important;
  height: var(--ui-bsiw) !important;
  font-size: var(--ui-bsiw) !important;
  line-height: var(--ui-bsiw) !important;
  display: inline-block;
}
.bsiw .bi.bi-bell-slash::before {
  position: relative;
  top: -0.065rem;
}

/*
.bob-icon {
    width: var(--ui-bob-icon) !important;
    height: var(--ui-bob-icon) !important;
    font-size: var(--ui-bob-icon) !important;
    line-height: var(--ui-bob-icon) !important;
    display: inline-block;

    //background: $steelblue;

    .bi {

        &.bi-bell-slash::before {
            position: relative;
            top: -0.065em;
        }
    }
}*/
.lazyload,
.lazyloading {
  background: #e5e7ed;
}

.lazyloading {
  -webkit-mask-image: linear-gradient(130deg, #253543 55%, rgba(0, 0, 0, 0.8) 75%, #253543 95%);
          mask-image: linear-gradient(130deg, #253543 55%, rgba(0, 0, 0, 0.8) 75%, #253543 95%);
  -webkit-mask-size: 200% 100%;
          mask-size: 200% 100%;
  -webkit-animation: lazyloading-wave 2s linear infinite;
          animation: lazyloading-wave 2s linear infinite;
}

@-webkit-keyframes lazyloading-wave {
  100% {
    -webkit-mask-position: -200% 0%;
            mask-position: -200% 0%;
  }
  0% {
    -webkit-mask-position: 0;
            mask-position: 0;
  }
}

@keyframes lazyloading-wave {
  100% {
    -webkit-mask-position: -200% 0%;
            mask-position: -200% 0%;
  }
  0% {
    -webkit-mask-position: 0;
            mask-position: 0;
  }
}
@-webkit-keyframes lazyloading {
  50% {
    opacity: 0.2;
  }
}
@keyframes lazyloading {
  50% {
    opacity: 0.2;
  }
}
.lazyloaded {
  background: initial;
}

.progress .progress-bar {
  border-radius: 0.25rem;
}

.badge:empty {
  display: none !important;
}

.fi:not(.no-glow) {
  box-shadow: inset 0.375rem 0.375rem 0.1875rem 0 rgba(255, 255, 255, 0.4509803922);
}

.bob_premium_content:empty + hr {
  display: none;
}

.gam_banner_my {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.gam_banner_my:empty {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.gam_banner_mt {
  margin-top: 1.25rem;
}
.gam_banner_mt:empty {
  margin-top: 0 !important;
}

.gam_banner_mb {
  margin-bottom: 1.25rem;
}
.gam_banner_mb:empty {
  margin-bottom: 0 !important;
}

.pac-container {
  z-index: 1037;
}

.active-filter-block {
  background-color: #f8f8fa;
}

.active-filter.active {
  background-color: #063462;
}
.active-filter.active .active-filter-checkmark {
  display: inline;
}
.active-filter.inactive {
  background-color: #cdd6e0;
}
.active-filter.inactive .active-filter-checkmark {
  display: none;
}

.faq-banner {
  background-color: #063462;
  background-size: cover;
  background-position: center;
}

.faq-category-button {
  -webkit-box-flex: 1;
          flex: 1;
  margin: 0.8rem;
  border-radius: 0.5em;
  color: #063462 !important;
  border: 0.125rem solid #063462;
  font-weight: 700;
  font-size: 1rem;
  padding: 2rem 0.75rem;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  min-width: 150px;
}
.faq-category-button img,
.faq-category-button svg {
  margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
  .faq-category-section {
    width: -webkit-calc(50% - 1em);
    width: calc(50% - 1em);
  }
}

.markdown.faqAnswer a {
  text-decoration: underline;
}

.toast-container {
  z-index: 1060;
}

.vue-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1040;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}
.vue-modal-container .vue-modal-overlay {
  background-color: #063462;
  opacity: 0.5;
  height: 100%;
  width: 100%;
  position: absolute;
}
.vue-modal-container .vue-modal-content {
  position: relative;
  background-color: white;
  z-index: 1;
  display: -webkit-box;
  display: flex;
  overscroll-behavior: contain;
  margin: 1.25rem !important;
  max-height: 90%;
}
.vue-modal-container .vue-modal-content button.btn-close {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
}
.vue-modal-container.vue-modal-toast {
  -webkit-box-pack: start;
          justify-content: start;
  pointer-events: none;
  top: 160px;
}
@media (min-width: 768px) {
  .vue-modal-container.vue-modal-toast {
    -webkit-box-align: end;
            align-items: end;
  }
}
.vue-modal-container.vue-modal-toast .vue-modal-content {
  pointer-events: auto;
  box-shadow: 0px 0px 16px 8px rgba(6, 52, 98, 0.15);
  border-radius: 4px;
}

.search-card-result {
  display: -webkit-box;
  display: flex;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
}
@media (max-width: 767.98px) {
  .search-card-result {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }
}
.search-card-result .search-card-img {
  position: relative;
  -webkit-box-flex: 0;
          flex: none;
  border-radius: 0.25rem 0 0 0.25rem;
  overflow: hidden;
}
@media (max-width: 575.98px) {
  .search-card-result .search-card-img {
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 0.25rem 0.25rem 0 0;
    padding: 0;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .search-card-result .search-card-img {
    width: 330px;
    height: 208px;
  }
}
@media (min-width: 992px) {
  .search-card-result .search-card-img {
    width: 430px;
    min-height: 100%;
  }
}
.search-card-result .search-card-img .heart-wrapper {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 2;
  left: auto;
}
.search-card-result .search-card-img .carousel-control-next,
.search-card-result .search-card-img .carousel-control-prev {
  opacity: 1 !important;
  width: 52px;
}
.search-card-result .search-card-img .carousel-control-next .carousel-control-next-icon,
.search-card-result .search-card-img .carousel-control-next .carousel-control-prev-icon,
.search-card-result .search-card-img .carousel-control-prev .carousel-control-next-icon,
.search-card-result .search-card-img .carousel-control-prev .carousel-control-prev-icon {
  display: none;
  border-radius: 100%;
  fill: #063462;
  color: #063462;
  stroke: #063462;
  background-size: 0.75rem;
}
.search-card-result .search-card-img .carousel-control-next .carousel-control-next-icon, .search-card-result .search-card-img .carousel-control-next .carousel-control-next-icon:hover,
.search-card-result .search-card-img .carousel-control-next .carousel-control-prev-icon,
.search-card-result .search-card-img .carousel-control-next .carousel-control-prev-icon:hover,
.search-card-result .search-card-img .carousel-control-prev .carousel-control-next-icon,
.search-card-result .search-card-img .carousel-control-prev .carousel-control-next-icon:hover,
.search-card-result .search-card-img .carousel-control-prev .carousel-control-prev-icon,
.search-card-result .search-card-img .carousel-control-prev .carousel-control-prev-icon:hover {
  background-color: white !important;
}
.search-card-result .search-card-img .carousel-indicators {
  margin-bottom: 0.5rem;
}
.search-card-result .search-card-img .carousel-indicators [data-bs-target] {
  opacity: 1 !important;
  border-radius: 100%;
  height: 0.5rem;
  width: 0.5rem;
  border: none !important;
}
.search-card-result .search-card-img .carousel-indicators [data-bs-target].active {
  background-color: #ff3944;
}
.search-card-result .search-card-img .carousel-indicators [data-bs-target]:not(.active) {
  background-color: #e5e7ed;
}
.search-card-result .search-card-img .carousel-inner {
  height: 100%;
}
.search-card-result .search-card-img .carousel-inner .carousel-item {
  height: 100%;
}
.search-card-result .search-card-img .counters {
  position: absolute;
  width: auto;
  height: auto;
  bottom: 0.5rem;
  top: auto;
  left: 0.5rem;
  display: -webkit-box;
  display: flex;
  z-index: 10;
  cursor: default;
  font-size: 0.875rem;
}
.search-card-result .search-card-img .counters .images-counter,
.search-card-result .search-card-img .counters .videos-counter {
  background: #ffffff;
  border-radius: 0.1875rem;
  margin-right: 0.625rem;
  padding: 0.25rem 0.5rem;
}
@media (max-width: 575.98px) {
  .search-card-result .search-card-img .counters .images-counter,
  .search-card-result .search-card-img .counters .videos-counter {
    font-size: 0.875rem;
    padding: 0.25rem;
  }
}
.search-card-result .search-card-img .counters .images-counter[data-count="0"],
.search-card-result .search-card-img .counters .videos-counter[data-count="0"] {
  display: none;
}
.search-card-result .search-card-content {
  -webkit-box-flex: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  overflow: hidden;
  padding: 0.75rem;
}
.search-card-result .search-card-content hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.search-card-result .search-card-content .fi {
  max-width: 1rem;
  margin-left: 0.375rem;
  border-radius: 0.1875rem;
  max-height: 0.75rem;
}
.search-card-result .search-card-content .fi:before {
  width: 100%;
  display: block;
}
.search-card-result .search-card-content .search-card-content-insurance {
  display: none;
}
@media (min-width: 992px) {
  .search-card-result .search-card-content .search-card-content-insurance {
    display: block;
  }
}
.search-card-result .search-card-content .search-card-content-reviews {
  display: block;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}
@media (max-width: 767.98px) {
  .search-card-result .search-card-content .search-card-content-reviews {
    display: none !important;
  }
}
.search-card-result .search-card-content .search-card-content-characteristics {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}
.search-card-result .search-card-content .search-card-content-characteristics .search-card-content-characteristics-desktop {
  display: block;
}
@media (max-width: 767.98px) {
  .search-card-result .search-card-content .search-card-content-characteristics .search-card-content-characteristics-desktop {
    display: none;
  }
}
.search-card-result .search-card-content .search-card-content-pricing {
  -webkit-box-flex: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  position: relative;
}
.search-card-result .search-card-content .search-card-content-pricing .font-price {
  font-size: -webkit-calc(1.275rem + 0.3629032258vw);
  font-size: calc(1.275rem + 0.3629032258vw);
}
@media (min-width: 992px) {
  .search-card-result .search-card-content .search-card-content-pricing .font-price {
    font-size: 1.5rem;
  }
}
.search-card-result .search-card-content .search-card-content-pricing .badge-pro {
  display: none;
}
@media (max-width: 767.98px) {
  .search-card-result .search-card-content .search-card-content-pricing .search-card-content-owner {
    display: block !important;
  }
  .search-card-result .search-card-content .search-card-content-pricing .font-price {
    font-size: 1.25rem;
  }
  .search-card-result .search-card-content .search-card-content-pricing .badge-pro {
    display: block !important;
  }
}
@media (min-width: 768px) {
  .search-card-result .search-card-content .search-card-content-pricing {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-align: start;
            align-items: start;
    -webkit-box-pack: center;
            justify-content: center;
  }
}
.search-card-result .search-card-content .search-card-content-owner {
  display: none;
}
@media (min-width: 768px) {
  .search-card-result .search-card-content .search-card-content-owner {
    display: block;
  }
}
.search-card-result:hover .carousel-control-next .carousel-control-next-icon,
.search-card-result:hover .carousel-control-next .carousel-control-prev-icon,
.search-card-result:hover .carousel-control-prev .carousel-control-next-icon,
.search-card-result:hover .carousel-control-prev .carousel-control-prev-icon {
  display: block !important;
}

.bullet-separator {
  font-size: 0.625rem;
  display: inline-block;
  margin: 0 0.5rem;
  vertical-align: top;
  line-height: 1.125rem;
}
.bullet-separator:before {
  content: "•";
}

.view-gallery .search-card-result {
  padding: 0 !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
          flex-direction: column !important;
}
.view-gallery .search-card-result .search-card-img {
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 0;
}
.view-gallery .search-card-result .images-counter,
.view-gallery .search-card-result .videos-counter {
  font-size: 1rem !important;
  padding: 0.25rem 0.5rem !important;
}
.view-gallery .search-card-result .search-card-content {
  padding: 0.75rem;
}
.view-gallery .search-card-result .search-card-content .search-card-content-reviews {
  display: none !important;
}
.view-gallery .search-card-result .search-card-content .search-card-content-owner,
.view-gallery .search-card-result .search-card-content .search-card-content-characteristics-desktop,
.view-gallery .search-card-result .search-card-content .search-card-content-insurance {
  display: none !important;
}
.view-gallery .search-card-result .search-card-content .search-card-content-pricing {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
  margin-bottom: 0 !important;
}
.view-gallery .search-card-result .search-card-content .search-card-content-pricing .search-card-content-owner {
  display: block !important;
}
.view-gallery .search-card-result .search-card-content .search-card-content-pricing .font-price {
  font-size: 1.25rem !important;
}
.view-gallery .search-card-result .search-card-content .search-card-content-pricing .badge-pro {
  display: block !important;
}

[data-bs-breakpoint-receiver]:empty {
  display: none !important;
}

@media (min-width: 768px) {
  .adsbygoogle.bob_search_banner_2,
  .adsbygoogle.bob_search_banner_3,
  .adsbygoogle.bob_search_banner_4,
  .adsbygoogle.bob_boat_banner_1,
  .adsbygoogle.bob_boat_banner_2,
  .adsbygoogle.bob_lp_ref_banner_2,
  .adsbygoogle.bob_lp_ref_banner_3,
  .adsbygoogle.bob_lp_type_banner_2 {
    max-width: 970px !important;
    height: 90px !important;
  }
  .adsbygoogle.bob_lp_ref_banner_4,
  .adsbygoogle.bob_lp_ref_banner_5,
  .adsbygoogle.bob_lp_type_banner_3,
  .adsbygoogle.bob_lp_type_banner_4 {
    height: 600px !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .adsbygoogle.bob_search_banner_2,
  .adsbygoogle.bob_search_banner_3,
  .adsbygoogle.bob_search_banner_4,
  .adsbygoogle.bob_boat_banner_1,
  .adsbygoogle.bob_boat_banner_2,
  .adsbygoogle.bob_lp_ref_banner_2,
  .adsbygoogle.bob_lp_ref_banner_3,
  .adsbygoogle.bob_lp_ref_banner_4,
  .adsbygoogle.bob_lp_ref_banner_5,
  .adsbygoogle.bob_lp_type_banner_2,
  .adsbygoogle.bob_lp_type_banner_3,
  .adsbygoogle.bob_lp_type_banner_4 {
    height: 90px !important;
  }
}
@media (max-width: 575.98px) {
  .adsbygoogle.bob_search_banner_2,
  .adsbygoogle.bob_search_banner_3 {
    height: 250px !important;
  }
  .adsbygoogle.bob_search_banner_4,
  .adsbygoogle.bob_boat_banner_1,
  .adsbygoogle.bob_boat_banner_2,
  .adsbygoogle.bob_lp_ref_banner_2,
  .adsbygoogle.bob_lp_ref_banner_3,
  .adsbygoogle.bob_lp_ref_banner_4,
  .adsbygoogle.bob_lp_ref_banner_5,
  .adsbygoogle.bob_lp_type_banner_2,
  .adsbygoogle.bob_lp_type_banner_3,
  .adsbygoogle.bob_lp_type_banner_4 {
    height: 100px !important;
  }
}
@media (min-width: 768px) {
  .border-md-primary {
    border: solid 1px #063462 !important;
  }
}
